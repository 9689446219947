<template>
  <div class="component-list-container">
    <div class="component-list-content">
      <div class="component-list" @dragstart="handleDragStart" :style="{ transform: `translateX(${translateX}px)` }">
        <div
          v-for="(item, index) in allComponentList"
          :key="index"
          class="list"
          draggable
          :data-index="index"
        >
          <span :class="[item.icon.indexOf('el') !== -1 ? item.icon : 'iconfont', item.icon.indexOf('el') !== -1 ? '' : 'icon-' + item.icon]"></span>
          <span>{{ item.label }}</span>
        </div>
        <el-dropdown>
          <div class="list">
            矩阵
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="dropMatrix">
            <el-dropdown-item v-for="m in matrixOptions" :key="m.type">
              <div @dragstart="handleDragStart">
                <div class="option" draggable :data-index="m.type" :key="m.type">
                  {{ m.label }}
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="component-list-prev" @click="prevClick" :disabled="prevDis">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="component-list-next" @click="nextClick" :disabled="nextDis">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import componentList from "@/custom-component/component-list";
import { matrixOptions } from "@/constants/editor.js";

export default {
  props: ['allComponentList'],
  data() {
    return {
      componentList,
      matrixOptions,
      prevDis: true,
      nextDis: true,
      translateX: 0
    };
  },
  mounted() {
    this.initView();
  },
  methods: {
    handleDragStart(e) {
      e.dataTransfer.setData("index", e.target.dataset.index);
    },
    initView() {
      let listElem = document.getElementsByClassName('component-list');
      let contentElem = document.getElementsByClassName('component-list-content');
      if (listElem) {
        listElem = listElem[0];
      }
      if (contentElem) {
        contentElem = contentElem[0];
      }
      this.listElem = listElem;
      this.contentElem = contentElem;
      if (contentElem.clientWidth < listElem.clientWidth) {
        // 宽度超出，需要左右箭头
        this.prevDis = true;
        this.nextDis = false;
        this.translateX = 0;
      } else {
        this.prevDis = true;
        this.nextDis = true;
        this.translateX = 0;
      }
    },
    prevClick() {
      if (this.prevDis) {
        return;
      }
      let finalX = this.translateX + 100;
      if (finalX >= 0) {
        finalX = 0;
        this.prevDis = true;
      }
      if (this.nextDis) {
        this.nextDis = false;
      }
      this.translateX = finalX;
    },
    nextClick() {
      if (this.nextDis) {
        return;
      }
      let finalX = this.translateX - 100;
      if ((this.contentElem.clientWidth - finalX) > this.listElem.clientWidth) {
        finalX = this.contentElem.clientWidth - this.listElem.clientWidth;
        this.nextDis = true;
      }
      if (this.prevDis) {
        this.prevDis = false;
      }
      this.translateX = finalX;
    }
  },
};
</script>

<style lang="scss" scoped>
.component-list-container {
  position: relative;
  width: 95%;
  left: 2.5%;
  padding: 0px 20px;
  .component-list-prev,
  .component-list-next {
    position: absolute;
    cursor: pointer;
    line-height: 68px;
    font-size: 20px;
    color: #909399;
    top: 0px;
    &[disabled] {
      cursor: not-allowed;
      color: #e7e7e7;
      &:hover {
        color: #e7e7e7;
      }
    }
    &:hover {
      color: #409EFF;
    }
  }
  .component-list-prev {
    left: 0px;
  }
  .component-list-next {
    right: 0px;
  }
  .component-list-content {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    display: inline-block;
    .component-list {
      // display: flex;
      // flex-wrap: nowrap;
      padding: 10px;
      // justify-content: center;
      float: left;
      transition: all .3s;
      display: flex;
      align-items: center;
      > div:last-child {
        .list {
          margin-right: 0px;
        }
      }
      .list {
        height: 40px;
        border: 1px solid #ddd;
        font-size: 12px;
        cursor: grab;
        text-align: center;
        color: #333;
        padding: 2px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        border-radius: 4px;
        // flex: none;
        &:active {
          cursor: grabbing;
        }
        .option {
          height: 30px;
        }

        .iconfont {
          margin-right: 4px;
          font-size: 20px;
        }

        .icon-wenben,
        .icon-tupian {
          font-size: 18px;
        }
      }
    }
  }
}
.dropMatrix {
  .el-dropdown-menu__item {
    height: 40px;
    line-height: 40px;
    width: 100px;
    text-align: center;
    font-size: 16px;
  }
}
</style>