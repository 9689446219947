<template>
    <el-dialog
        title="批量设置当前页面的按钮"
        :visible="isShowModal"
        width="70%"
        custom-class="batch-set-btn-dialog"
        @close="hide"
        :destroy-on-close="true"
    >
        <div>
            <div style="marginBottom:10px;">
                <el-button type="primary" size="mini" @click="batchSet">批量设置</el-button>
            </div>
            <el-table
                :data="allDatas"
                border
                style="width: 100%"
                @selection-change="handleCheckedChange"
            >
                <el-table-column type="selection" min-width="60" fixed="left"> </el-table-column>
                <el-table-column prop="order" label="序号" min-width="60" align="center" fixed="left">
                    <template slot-scope="scope">
                        {{scope.$index + 1}}
                    </template>
                </el-table-column>
                <el-table-column
                    v-for="cell in allCells"
                    :key="cell.key"
                    :prop="cell.key"
                    :align="cell.align || 'center'"
                    :min-width="cell.width"
                    :label="cell.label"
                    :fixed="cell.fixed"
                >
                    <template slot-scope="scope">
                        <span v-if="cell.key === 'fontFamily'">{{fontFamilyName(scope.row.style[cell.key])}}</span>
                        <span v-else-if="notStyleKeys.indexOf(cell.key) !== -1">{{scope.row[cell.key]}}</span>
                        <template v-else-if="imgKes.indexOf(cell.key) !== -1">
                            <img v-if="scope.row.style[cell.key]" class="batch-set-btn-imgs" :src="getPicSrc(scope.row.style[cell.key])" />
                        </template>
                        <span v-else>{{scope.row.style[cell.key]}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="hide">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
        <BatchSetBtnsAttr
            ref="batchSetBtnsAttrDialog"
            @confirm="btnAttrSetConfirm"
        />
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { fontFamilyOptions } from '@/utils/attrNameData';
import BatchSetBtnsAttr from './BatchSetBtnsAttr';

export default {
    data() {
        return {
            fontFamilyOptions,
            isShowModal: false,
            allCells: [
                { label: "按钮名称", key: "propValue", width: "100", fixed: 'left' },
                { label: "按压名称", key: "btnPressName", width: "100" },
                { label: "别名", key: "alias", width: "100" },
                { label: "字体颜色", key: "color", width: "100" },
                { label: "背景图", key: "backgroundImage", width: "200" },
                { label: "背景颜色", key: "backgroundColor", width: "100" },
                { label: "按压背景图", key: "pressBackgroundImage", width: "200" },
                { label: "按压背景颜色", key: "pressBackgroundColor", width: "100" },
                { label: "字体", key: "fontFamily", width: "100" },
                { label: "字体大小", key: "fontSize", width: "100" }
            ],
            notStyleKeys: ['propValue'],
            imgKes: ['backgroundImage', 'pressBackgroundImage'],
            allDatas: [],
            checkDatas: []
        };
    },
    components: {
        BatchSetBtnsAttr
    },
    computed: {
        ...mapState(["componentData"]),
        fontFamilyName() {
            return (val) => {
                if (val) {
                    let data = this.fontFamilyOptions.filter((c) => c.id === val);
                    return data && data[0] && data[0].text || '';
                }
                return '';
            }
        },
        getPicSrc() {
            return (src) => {
                if (!src) return '';
                if (src.indexOf('(') !== -1 && src.indexOf(')') !== -1) {
                    return src.split("(")[1]?.split(")")[0];
                } else {
                    return src;
                }
            };
        },
    },
    methods: {
        show() {
            this.allDatas = JSON.parse(JSON.stringify(this.componentData.filter((c) => c.component === "v-button")));
            console.log(this.allDatas);
            this.isShowModal = true;
        },
        hide() {
            this.isShowModal = false;
        },
        confirm() {
            this.$emit('confirm', this.allDatas);
            this.hide();
        },
        btnAttrSetConfirm(datas) {
            if (Object.keys(datas).length === 0) {
                return;
            }
            this.checkDatas.map((item) => {
                let dataInd = this.allDatas.findIndex((c) => c.id === item.id);
                Object.keys(datas).map((key) => {
                    if (key === 'style') {
                        // style的属性需要再次遍历
                        Object.keys(datas[key]).map((styleKey) => {
                            this.allDatas[dataInd][key][styleKey] = datas[key][styleKey];
                        });
                    } else {
                        this.allDatas[dataInd][key] = datas[key];
                    }
                });
            });
            console.log(this.allDatas);
        },
        batchSet() {
            if (this.checkDatas.length == 0) {
                this.$message.error('请先勾选要批量设置的按钮');
                return;
            }
            this.$refs.batchSetBtnsAttrDialog.show();
        },
        handleCheckedChange(s) {
            this.checkDatas = s;
        }
    }
}
</script>
<style lang="scss">
.batch-set-btn-dialog {
    .el-dialog__body {
        padding-top: 10px;
    }
    .batch-set-btn-imgs {
        width: 170px;
        height: 116px;
    }
}
</style>
