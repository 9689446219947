<template>
  <div @click="handleClick">
    <component
      :is="config.component"
      class="component"
      :style="curStyle"
      :styleDatas="config.style"
      :prop-value="config.propValue"
      :element="config"
      :allBtnPressMap="allBtnPressMap"
      :isPreview="true"
      :currentComps="currentComps"
      @changeCurComp="changeCurComp"
      @changePage="changePage"
      @changeBtnPress="changeBtnPress"
    />
  </div>
</template>

<script>
import { getStyle } from '@/utils/style'
import runAnimation from '@/utils/runAnimation'
import { mixins } from '@/utils/events'

export default {
  mixins: [mixins],
  props: {
    config: {
      type: Object,
      require: true,
      default: () => {},
    },
    currentComps: {
      type: String,
      require: true,
    },
    allBtnPressMap: {
      type: Object,
      require: true,
    },
  },
  computed: {
    curStyle() {
      let filters =
        this.config.component === 'v-button'
          ? ['backgroundImage', 'backgroundColor']
          : []
      return getStyle(this.config.style, filters)
    },
  },
  mounted() {
    runAnimation(this.$el, this.config.animations)
  },
  methods: {
    getStyle,

    handleClick() {
      const events = this.config.events
      // Object.keys(events).forEach((event) => {
      //   this[event](events[event]);
      // });
    },
    changeCurComp(datas) {
      this.$emit('changeCurComp', datas)
    },
    changePage(key) {
      this.$emit('changePage', key)
    },
    changeBtnPress(arr) {
      this.$emit('changeBtnPress', arr)
    },
  },
}
</script>

<style lang="scss" scoped>
.component {
  position: absolute;
}
</style>
