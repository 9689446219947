var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"components"},[_c('el-tree',{ref:"compTree",attrs:{"data":_vm.treeDatas,"node-key":"id","default-expand-all":"","highlight-current":true,"expand-on-click-node":false},on:{"current-change":_vm.nodeChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"comp-tree-node",style:({ background: _vm.isError(data.id) ? '#fda6a6' : 'inherit' })},[_c('div',{staticClass:"comp-left-label"},[(_vm.componentIconConfig[data.component])?_c('i',{class:[
            'iconfont',
            _vm.componentIconConfig[data.component].indexOf('el') !== -1
              ? _vm.componentIconConfig[data.component]
              : ("icon-" + (_vm.componentIconConfig[data.component])) ]}):_vm._e(),_c('span',{attrs:{"title":data.label}},[_vm._v(_vm._s(data.component === 'v-button' ? data.propValue : data.label))]),(data.id != 'Back')?[(data.compType === _vm.ECOLOGICAL_SIGN || node.level > 1)?_c('el-tag',{attrs:{"size":"mini","type":"danger","effect":"dark"}},[_vm._v("生态控件")]):_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v("基本控件")])]:_vm._e()],2),(data.id != 'Back')?_c('span',{staticClass:"tree-right-btn"},[(data.isLock)?_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.unlock(data)}}},[_c('i',{staticClass:"el-icon-lock"})]):_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.lock(data)}}},[_c('i',{staticClass:"el-icon-unlock"})]),_c('el-popconfirm',{attrs:{"title":data.compType === _vm.ECOLOGICAL_SIGN
              ? '删除当前按钮会清空组内所有按钮，确定要删除吗？'
              : '确定要删除吗？'},on:{"confirm":function($event){return _vm.deleteC(data)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","size":"mini"},slot:"reference"},[_c('i',{staticClass:"el-icon-delete"})])],1)],1):_vm._e()])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }