<template>
  <div class="ecological-component-list-container">
    <div class="ecological-component-list-content">
      <div class="ecological-component-list" @dragstart="handleDragStart" :style="{ transform: `translateX(${translateX}px)` }">
        <div
          v-for="(item, index) in componentList"
          :key="item.id"
          class="list"
          draggable
          :data-index="index"
        >
          <i class="el-icon-files" />
          <span style="margin-left: 3px;">{{ item.ecology_name }}</span>
        </div>
      </div>
    </div>
    <div class="ecological-component-list-prev" @click="prevClick" :disabled="prevDis">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="ecological-component-list-next" @click="nextClick" :disabled="nextDis">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import { ECOLOGICAL_SIGN } from '@/constants/editor';
export default {
  props: ['componentList'],
  data() {
    return {
      prevDis: true,
      nextDis: true,
      translateX: 0
    };
  },
  mounted() {
    this.initView();
  },
  methods: {
    handleDragStart(e) {
      e.dataTransfer.setData("compType", ECOLOGICAL_SIGN);
      e.dataTransfer.setData("index", e.target.dataset.index);
    },
    initView() {
      let listElem = document.getElementsByClassName('ecological-component-list');
      let contentElem = document.getElementsByClassName('ecological-component-list-content');
      if (listElem) {
        listElem = listElem[0];
      }
      if (contentElem) {
        contentElem = contentElem[0];
      }
      this.listElem = listElem;
      this.contentElem = contentElem;
      if (contentElem.clientWidth < listElem.clientWidth) {
        // 宽度超出，需要左右箭头
        this.prevDis = true;
        this.nextDis = false;
        this.translateX = 0;
      } else {
        this.prevDis = true;
        this.nextDis = true;
        this.translateX = 0;
      }
    },
    prevClick() {
      if (this.prevDis) {
        return;
      }
      let finalX = this.translateX + 100;
      if (finalX >= 0) {
        finalX = 0;
        this.prevDis = true;
      }
      if (this.nextDis) {
        this.nextDis = false;
      }
      this.translateX = finalX;
    },
    nextClick() {
      if (this.nextDis) {
        return;
      }
      let finalX = this.translateX - 100;
      if ((this.contentElem.clientWidth - finalX) > this.listElem.clientWidth) {
        finalX = this.contentElem.clientWidth - this.listElem.clientWidth;
        this.nextDis = true;
      }
      if (this.prevDis) {
        this.prevDis = false;
      }
      this.translateX = finalX;
    }
  },
};
</script>

<style lang="scss" scoped>
.ecological-component-list-container {
  position: relative;
  width: 95%;
  left: 2.5%;
  padding: 0px 20px;
  height: 69px;
  .ecological-component-list-prev,
  .ecological-component-list-next {
    position: absolute;
    cursor: pointer;
    line-height: 68px;
    font-size: 20px;
    color: #909399;
    top: 0px;
    &[disabled] {
      cursor: not-allowed;
      color: #e7e7e7;
      &:hover {
        color: #e7e7e7;
      }
    }
    &:hover {
      color: #409EFF;
    }
  }
  .ecological-component-list-prev {
    left: 0px;
  }
  .ecological-component-list-next {
    right: 0px;
  }
  .ecological-component-list-content {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    display: inline-block;
    .ecological-component-list {
      // display: flex;
      // flex-wrap: nowrap;
      padding: 10px;
      // justify-content: center;
      float: left;
      transition: all .3s;
      > div:last-child {
        .list {
          margin-right: 0px;
        }
      }
      .list {
        height: 40px;
        border: 1px solid #ddd;
        font-size: 12px;
        cursor: grab;
        text-align: center;
        color: #333;
        padding: 2px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        border-radius: 4px;
        // flex: none;
        &:active {
          cursor: grabbing;
        }
        .option {
          height: 30px;
        }

        .iconfont {
          margin-right: 4px;
          font-size: 20px;
        }

        .icon-wenben,
        .icon-tupian {
          font-size: 18px;
        }
      }
    }
  }
}
</style>