<template>
  <div
    :style="{
      left: start.x + 'px',
      top: start.y + 'px',
      width: width + 'px',
      height: height + 'px',
    }"
    class="area"
    @mousedown="handleMouseDownOnArea"
  ></div>
</template>

<script>
export default {
  props: {
    start: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleMouseDownOnArea(e) {
      e.stopPropagation()
      let startX = e.clientX
      let startY = e.clientY
      let hasMove = false
      const move = (moveEvent) => {
        hasMove = true
        const curX = moveEvent.clientX
        const curY = moveEvent.clientY
        const missX = curX - startX
        const missY = curY - startY
        startX = curX
        startY = curY
        this.$emit('areaMove', { missX, missY })
      }

      const up = () => {
        hasMove && this.$store.commit('recordSnapshot')
        document.removeEventListener('mousemove', move)
        document.removeEventListener('mouseup', up)
      }

      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', up)
    },
  },
}
</script>

<style lang="scss" scoped>
.area {
  border: 1px solid #70c0ff;
  position: absolute;
  &:hover {
    cursor: move;
  }
}
</style>
