<template>
  <div class="home-container">
    <div class="home">
      <Toolbar
        :projectName="projectName"
        :changeProjectName="changeProjectName"
        @copyPage="copyPage"
      />
      <!-- 可选择的组件 -->
      <el-tabs v-model="compActiveName" class="comp-tabs">
        <el-tab-pane label="基础组件" name="baseComp">
          <ComponentList :allComponentList="allComponentList" />
        </el-tab-pane>
        <el-tab-pane label="比翼鸟生态组件" name="ecologyComp">
          <EcologicalComponent :componentList="ecologicalComp" />
        </el-tab-pane>
      </el-tabs>
      <main>
        <!-- 左侧组件列表 -->
        <section class="left">
          <div class="pageSelector">
            <el-select
              v-model="currentPage"
              class="select"
              placeholder="请选择"
              size="medium"
              ref="pageSelector"
            >
              <el-option
                v-for="item in pageOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                class="pageItem"
              >
                <span>
                  {{ item.label }}
                </span>
                <span
                  v-if="pageOptions.length > 1"
                  class="el-icon-delete"
                  @click="deletePage($event, item.value)"
                />
              </el-option>
            </el-select>
            <div class="sortBtn" @click="showSortDialog">排序</div>
          </div>
          <DragableSort ref="dragableSort" />
          <CurrentPageComponents :pageKey="currentPage" />
          <div class="addPageBtn" @click="addNewPage">添加新页面</div>
        </section>
        <!-- 中间画布 -->
        <section class="center">
          <div
            class="content"
            @drop="handleDrop"
            @dragover="handleDragOver"
            @mousedown="handleMouseDown"
            @mouseup="deselectCurComponent"
            @mouseleave="eidtorOnBlurHandler"
            @mouseenter="editorOnFocusHandler"
          >
            <Editor />
            <!-- 右击菜单 -->
            <ContextMenu />
          </div>
        </section>
        <!-- 右侧属性列表 -->
        <section class="right">
          <el-tabs v-model="activeName">
            <el-tab-pane label="基本属性" name="attr">
              <AttrList v-if="curComponent" />
              <p v-else class="placeholder">请选择组件</p>
            </el-tab-pane>

            <el-tab-pane
              label="网络配置"
              name="networkConfig"
              v-if="isHasDirectivesAndEvents('networkConfig')"
            >
              <NetworkConfig v-if="curComponent" />
              <p v-else class="placeholder">请选择组件</p>
            </el-tab-pane>

            <el-tab-pane
              label="事件配置"
              name="events"
              v-if="isHasDirectivesAndEvents('events')"
            >
              <EventsAttrs v-if="curComponent" />
              <p v-else class="placeholder">请选择组件</p>
            </el-tab-pane>

            <el-tab-pane
              label="网络状态"
              name="activeDetectionConfig"
              v-if="isHasDirectivesAndEvents('activeDetectionConfig')"
            >
              <ActiveDetectionConfig v-if="curComponent" />
              <p v-else class="placeholder">请选择组件</p>
            </el-tab-pane>

            <el-tab-pane
              label="开关状态"
              name="passiveMonitorConfig"
              v-if="isHasDirectivesAndEvents('passiveMonitorConfig')"
            >
              <PassiveMonitorConfig v-if="curComponent" />
              <p v-else class="placeholder">请选择组件</p>
            </el-tab-pane>
          </el-tabs>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/Editor/index'
import ComponentList from '@/components/ComponentList' // 左侧列表组件
import EcologicalComponent from '@/components/EcologicalComponent'
import CurrentPageComponents from '@/components/CurrentPageComponents' //选择页所使用的组件
import AttrList from '@/components/AttrList' // 右侧属性列表
import AnimationList from '@/components/AnimationList' // 右侧动画列表
import EventsAttrs from '@/components/EventsAttrs'
import NetworkConfig from '@/components/NetworkConfig' // 右侧事件列表
import componentList, {
  matrixComponents,
} from '@/custom-component/component-list' // 左侧列表数据
import DragableSort from '@/components/DragableSort.vue'
import Toolbar from '@/components/Toolbar'
import { deepCopy, getComponentsByType, uuid } from '@/utils/utils'
import { BACK_COMPONENT, ATTRS_VALIDATE_MAP } from '@/utils/attrNameData'
import { mapState } from 'vuex'
import generateID from '@/utils/generateID'
import { listenGlobalKeyDown } from '@/utils/shortcutKey'
import ContextMenu from '@/components/Editor/ContextMenu'
import eventBus from '@/utils/eventBus'
import { ECOLOGICAL_SIGN, ECOLOGICAL_CHILDREN_SIGN } from '@/constants/editor'
import ActiveDetectionConfig from '@/components/ActiveDetectionConfig'
import PassiveMonitorConfig from '@/components/PassiveMonitorConfig'

import moment from 'moment'
export default {
  components: {
    Editor,
    ComponentList,
    AttrList,
    AnimationList,
    EventsAttrs,
    NetworkConfig,
    Toolbar,
    CurrentPageComponents,
    DragableSort,
    ContextMenu,
    EcologicalComponent,
    ActiveDetectionConfig,
    PassiveMonitorConfig,
  },
  data() {
    return {
      allComponentList: Object.assign([], componentList),
      activeName: 'attr',
      reSelectAnimateIndex: undefined,
      page_sort: [1],
      currentPage: 1,
      projectName: `模板-${moment().format('YYYY-MM-DD HH:mm')}`,
      compActiveName: 'baseComp',
      ecologicalComp: [],
      addNewPageBtnDis: false,
    }
  },
  watch: {
    currentPage(key) {
      this.$store.commit('setSelectedCurrentPage', key)
      //默认选中第一个组件
      this.$store.commit('setCurComponent', {
        component: this.componentData[0],
      })
      this.restore(key)
    },
    curComponent(newV) {
      this.activeName = 'attr'
    },
  },
  computed: {
    ...mapState([
      'componentData',
      'curComponent',
      'isClickComponent',
      'canvasStyleData',
      'editor',
      'pageOptions',
      'selectedCurrentPage',
    ]),
    isHasDirectivesAndEvents() {
      return (type) => {
        let isEcoShow =
          type === 'networkConfig'
            ? this.curComponent &&
              this.curComponent.compType !== ECOLOGICAL_CHILDREN_SIGN
            : true
        return this.curComponent && isEcoShow && this.curComponent[type]
      }
    },
  },
  created() {
    eventBus.$on('inputValidate', this.inputValChange)
    this.setLocalData()
    this.getListComp()
  },
  methods: {
    async getListComp() {
      try {
        this.$changeLoadingState(true)
        const ret = await Promise.all([
          this.$request.getEcologyList(),
          this.$request.getListType({
            custom_type: 'weather',
          }),
        ])
        const compArr = (ret && ret[0] && ret[0].data) || []
        this.ecologicalComp = compArr

        const datas = (ret && ret[1] && ret[1].data) || []
        this.$store.commit(
          'setWeatherAttrConfig',
          datas.map(({ custom_url: id, custom_name: text }) => ({ id, text }))
        )
        // 设置天气组件的默认样式
        for (let i = 0; i < this.allComponentList.length; i++) {
          if (this.allComponentList[i].component === 'Weather') {
            this.allComponentList[i].style.imgStyle =
              (datas[0] && datas[0].custom_url) || ''
            this.allComponentList[i].style.imgStyleName =
              (datas[0] && datas[0].custom_name) || ''
            break
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.$changeLoadingState(false)
    },
    inputValChange({ key, val, id, otherVal, pageKey, prefixKey }) {
      const validateFunc =
        ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key].validate
      if (validateFunc && typeof validateFunc === 'function') {
        let _this = this
        validateFunc(
          val,
          (errorMsg) => {
            pageKey = pageKey || this.selectedCurrentPage
            const remainErrorData =
              (sessionStorage.getItem('canvasErrorMap') &&
                JSON.parse(sessionStorage.getItem('canvasErrorMap'))) ||
              {}
            if (!remainErrorData[pageKey][id]) {
              remainErrorData[pageKey][id] = {}
            }
            if (prefixKey) {
              if (!remainErrorData[pageKey][id][prefixKey]) {
                remainErrorData[pageKey][id][prefixKey] = {}
              }
              remainErrorData[pageKey][id][prefixKey][key] = errorMsg || ''
            } else {
              remainErrorData[pageKey][id][key] = errorMsg || ''
            }
            sessionStorage.setItem(
              'canvasErrorMap',
              JSON.stringify(remainErrorData)
            )
            _this.$store.commit('setCurErrorMap')
          },
          otherVal
        )
      }
    },
    initData() {
      //还原页面的个数
      this.restorePageOptions()
      this.restore(this.currentPage)
      // 全局监听按键事件
      listenGlobalKeyDown()
    },
    eidtorOnBlurHandler() {
      this.$store.commit('switchIsEditorOnBlur', true)
    },
    editorOnFocusHandler() {
      this.$store.commit('switchIsEditorOnBlur', false)
    },
    handleInitData(data) {
      let dataObj = JSON.parse(data)
      Object.keys(dataObj).forEach((pageId) => {
        let pageData = dataObj[pageId]
        pageData.forEach((item, ind) => {
          if (item.component === 'v-button') {
            // 按钮初始值设置，没有值的时候才需要设置
            if (!item.style.backgroundType) {
              if (item.style.backgroundImage) {
                dataObj[pageId][ind].style.backgroundType = 'icon'
              } else {
                dataObj[pageId][ind].style.backgroundType = 'color'
              }
            }
            if (!item.style.pressBackgroundType) {
              if (item.style.pressBackgroundImage) {
                dataObj[pageId][ind].style.pressBackgroundType = 'icon'
              } else {
                dataObj[pageId][ind].style.pressBackgroundType = 'color'
              }
            }
          }
        })
      })
      return JSON.stringify(dataObj)
    },
    setLocalData() {
      const { id, isCommonTemplate } = this.$route.query
      if (!id) {
        this.initData()
        return
      }
      this.$request.getProjectInfo({ id, isCommonTemplate }).then((res) => {
        if (res.status != 'success') {
          this.$message.error(res.msg)
          return
        }
        const {
          panel_data,
          project_height,
          project_width,
          project_name,
          page_sort,
        } = res.data || {}

        this.page_sort = page_sort
        this.projectName = project_name
        // console.log(JSON.stringify(panel_data));
        // 由于图片新增了字段，历史数据兼容需要处理下
        let data = this.handleInitData(panel_data)
        sessionStorage.setItem('canvasData', data)
        this.initErrorMap()
        sessionStorage.setItem(
          'canvasStyle',
          JSON.stringify({
            height: Number(project_height),
            width: Number(project_width),
            scale: 100,
            [this.currentPage]: {
              height: Number(project_height),
              width: Number(project_width),
              scale: 100,
            },
          })
        )
        this.$store.commit('setProjectStyle', {
          height: Number(project_height),
          width: Number(project_width),
          scale: 100,
        })
        this.initData()
      })
    },
    initErrorMap() {
      let map = {}
      this.page_sort.map((id) => {
        map[id] = {}
      })
      sessionStorage.setItem('canvasErrorMap', JSON.stringify(map))
    },
    restorePageOptions() {
      if (sessionStorage.getItem('canvasData')) {
        const data = JSON.parse(sessionStorage.getItem('canvasData'))
        const arr = []
        for (const p in data) {
          arr.push({
            label: data[p][0].style.pageName,
            value: p,
          })
        }
        const sortedArr = this.page_sort.reduce((res, page) => {
          let arrItem = arr.find((item) => item.value + '' == page + '')
          if (arrItem) {
            res.push(arrItem)
          }
          return res
        }, [])
        this.$store.commit('setPageOptions', sortedArr)
        this.currentPage = sortedArr[0].value
      }
    },
    restore(key) {
      // 用保存的数据恢复画布
      if (sessionStorage.getItem('canvasData')) {
        const canvasData = JSON.parse(sessionStorage.getItem('canvasData'))
        if (!canvasData[key]) {
          this.$store.commit('setCurComponent', {
            component: null,
            index: null,
          })
          this.$store.commit('setComponentData', [
            BACK_COMPONENT(`新页面${this.pageOptions.length}`),
          ])
          this.$store.commit('recordSnapshot')
        } else {
          this.$store.commit('setComponentData', canvasData[key])
          this.$store.commit('recordSnapshot')
        }
      } else {
        sessionStorage.setItem(
          'canvasData',
          JSON.stringify({
            1: [BACK_COMPONENT()],
          })
        )
        sessionStorage.setItem(
          'canvasErrorMap',
          JSON.stringify({
            1: {},
          })
        )
        this.$store.commit('setCurErrorMap')
      }

      if (sessionStorage.getItem('canvasStyle')) {
        const canvasStyle = JSON.parse(sessionStorage.getItem('canvasStyle'))
        const width = this.$store.state.canvasStyleData.width
        const height = this.$store.state.canvasStyleData.height
        const scale = this.$store.state.canvasStyleData.scale
        this.$store.commit(
          'setCanvasStyle',
          canvasStyle[key] || { width: width, height: height, scale: scale }
        )
      } else {
        sessionStorage.setItem(
          'canvasStyle',
          JSON.stringify({ width: 1200, height: 740, scale: 100 })
        )
      }
    },
    showSortDialog() {
      this.$refs.dragableSort.switchDialogVisible(true)
    },
    changeProjectName(v) {
      this.projectName = v
    },

    resetID(data) {
      data.forEach((item) => {
        if (item.id == 'Back') return
        item.id = generateID()
      })
      return data
    },
    handlerEcologicalCompProps(component) {
      component.compType = ECOLOGICAL_SIGN
      component.component = 'EcologicalGroup'
      component.networkConfig = component.networkConfig || {
        ipAdress: '',
        port: '',
      }
      if (component.propValue) {
        component.propValue.map((item, index) => {
          component.propValue[index].id = uuid()
          component.propValue[index].parentid = component.id
          component.propValue[index].compType = ECOLOGICAL_CHILDREN_SIGN
        })
      }
      return component
    },
    handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()
      const index = e.dataTransfer.getData('index')
      const rectInfo = this.editor.getBoundingClientRect()
      if (
        e.clientY < rectInfo.top ||
        e.clientY > rectInfo.bottom ||
        e.clientX < rectInfo.left ||
        e.clientX > rectInfo.right
      ) {
        // 如果没有拖拽在画布里面，不能渲染
        return
      }
      const compType = e.dataTransfer.getData('compType')
      let component = {}
      if (compType === ECOLOGICAL_SIGN) {
        try {
          component = JSON.parse(this.ecologicalComp[index].ecology_data)
        } catch (error) {
          this.$message.error('生态组件数据不正确，请检查')
          return
        }
        component.id = uuid()
        // 增加生态组件的标志
        component = this.handlerEcologicalCompProps(component)
      } else if (index.indexOf('matrix') > -1) {
        component = deepCopy(matrixComponents[index])
        component.id = uuid()
      } else {
        component = deepCopy(this.allComponentList[index])
        component.id = uuid()
      }

      if (component.component == 'v-button') {
        const { btns } =
          (atob(this.$route.query.limits) &&
            JSON.parse(atob(this.$route.query.limits))) ||
          {}
        const allBtns = getComponentsByType('v-button')
        if (allBtns.length >= btns * 1) {
          this.$message.error('按钮个数不得超过最高限制个数')
          return
        }
      }
      let currentScale = this.editor.getAttribute('data-scale')
      const canvasStyle = JSON.parse(sessionStorage.getItem('canvasStyle'))
      const currentPageStyle = canvasStyle[this.selectedCurrentPage]
      component.style.top = Math.ceil((e.clientY - rectInfo.y) / currentScale)
      if (
        component.style.height + component.style.top >
        currentPageStyle.height
      ) {
        // 超出底部了
        component.style.top = currentPageStyle.height - component.style.height
      }
      component.style.left = Math.ceil((e.clientX - rectInfo.x) / currentScale)
      if (
        component.style.width + component.style.left >
        currentPageStyle.width
      ) {
        // 超出右侧了
        component.style.left = currentPageStyle.width - component.style.width
      }
      if (compType === ECOLOGICAL_SIGN) {
        component = this.setChildCompPos(component)
      }
      eventBus.$emit('hideArea')
      component.groupStyle = {}
      this.$store.commit('addComponent', { component })
      this.$store.commit('recordSnapshot')
    },
    setChildCompPos(component) {
      if (component.propValue && component.propValue.length !== 0) {
        const compH = component.style.height
        const compW = component.style.width
        const compTop = component.style.top
        const compLeft = component.style.left
        component.propValue.map((item, index) => {
          component.propValue[index].style.top = parseInt(
            (parseFloat(item.groupStyle.top) / 100) * compH + compTop
          )
          component.propValue[index].style.left = parseInt(
            (parseFloat(item.groupStyle.left) / 100) * compW + compLeft
          )
        })
      }
      return component
    },
    handleDragOver(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },

    handleMouseDown() {
      // this.$store.commit("setCurComponent", {
      //   component: this.componentData[0],
      //   index: null,
      // });
      // this.$store.commit("setClickComponentStatus", false);
    },

    deselectCurComponent(e) {
      //当取消选中一个组件时,则默认选中背景组件
      if (!this.isClickComponent) {
      }

      // 0 左击 1 滚轮 2 右击
      if (e.button != 2) {
        this.$store.commit('hideContextMenu')
      }
    },
    addNewPage() {
      if (this.addNewPageBtnDis) {
        return
      }
      this.addNewPageBtnDis = true
      setTimeout(() => {
        this.addNewPageBtnDis = false
      }, 700)
      const { pages } =
        (atob(this.$route.query.limits) &&
          JSON.parse(atob(this.$route.query.limits))) ||
        {}
      //判断是否超套餐个数
      if (this.pageOptions.length >= pages * 1) {
        this.$message.error('不得超过页面最高限制个数')
        return
      }
      const id = uuid()
      this.$store.commit('setPageOptions', [
        ...this.pageOptions,
        {
          label: `新页面${this.pageOptions.length + 1}`,
          value: id,
        },
      ])
      this.currentPage = id
    },
    copyPageData() {
      const id = uuid()
      let currentPageOpt = this.pageOptions.filter((item) => {
        return item.value === this.currentPage
      })
      const currentPageName = currentPageOpt[0].label + 'copy'
      let data = this.componentData.concat([])
      data[0].style.pageName = currentPageName
      // 复制页面的组件id不能重复
      data.map((item, ind) => {
        if (item.component !== 'Back') {
          data[ind].id = uuid()
        }
      })
      return {
        id,
        data,
      }
    },
    copyPage() {
      // 先设置复制后的数据
      const copyDatas = this.copyPageData()
      const id = copyDatas.id
      let currentPageOpt = this.pageOptions.filter((item) => {
        return item.value === this.currentPage
      })
      const currentPageName = currentPageOpt[0].label + 'copy'

      let data = JSON.parse(window.sessionStorage.getItem('canvasData'))
      data[id] = copyDatas.data
      sessionStorage.setItem('canvasData', JSON.stringify(data))

      let errorData = JSON.parse(
        window.sessionStorage.getItem('canvasErrorMap')
      )
      errorData[id] = errorData[this.currentPage]
      sessionStorage.setItem('canvasErrorMap', JSON.stringify(errorData))
      this.$store.commit('setCurErrorMap')

      let styleData = JSON.parse(window.sessionStorage.getItem('canvasStyle'))
      styleData[id] = styleData[this.currentPage]
      sessionStorage.setItem('canvasStyle', JSON.stringify(styleData))

      this.page_sort.push(id)
      this.$store.commit('setPageOptions', [
        ...this.pageOptions,
        {
          label: currentPageName,
          value: id,
        },
      ])
      // console.log(this.pageOptions);
      this.currentPage = id
    },
    deletePage(e, v) {
      e.stopPropagation()
      e.preventDefault()
      const pages = this.pageOptions.filter((item) => item.value != v)
      this.$store.commit('setPageOptions', pages)
      this.currentPage = pages[0].value
      this.$refs.pageSelector.blur()
      //删除数据
      const data = JSON.parse(window.sessionStorage.getItem('canvasData'))
      delete data[v]
      sessionStorage.setItem('canvasData', JSON.stringify(data))
      this.resetErrorMap()
    },
    resetErrorMap() {
      const data = JSON.parse(window.sessionStorage.getItem('canvasErrorMap'))
      for (let key in data) {
        if (this.page_sort.indexOf(key) === -1) {
          delete data[key]
        }
      }
      sessionStorage.setItem('canvasErrorMap', JSON.stringify(data))
      this.$store.commit('setCurErrorMap')
    },
  },
}
</script>

<style lang="scss" scoped>
.comp-tabs {
  height: auto;
}
</style>
<style lang="scss">
@import url(../../assets/fonts/index.css);
.comp-tabs {
  .el-tabs__header {
    margin: 0 0 6px;
  }
  .el-tabs__nav-scroll {
    text-align: center;
  }
  .el-tabs__nav {
    display: inline-block;
    float: none;
  }
}
.home-container {
  overflow: auto;
  .attr-error-tip {
    font-size: 12px;
    position: absolute;
    top: 100%;
    color: red;
    left: 56px;
    right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.home {
  height: 100vh;
  background: #fff;
  min-width: 1170px;

  main {
    height: calc(100% - 59px - 114px);
    position: relative;
    user-select: none; // 避免拖拽时选中
    .left {
      position: absolute;
      height: 100%;
      width: 290px;
      left: 0;
      top: 0;
      padding-top: 10px;
      .pageSelector {
        padding: 0 20px;
        display: flex;
        height: 36px;
        .el-input__inner {
          // height: 46px;
          width: 170px;
        }
        .sortBtn {
          background-color: #fdfeff;
          border: 1px solid #b8d6fc;
          color: #569ef8;
          padding: 7px 15px;
          margin-left: 10px;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          cursor: pointer;
          font-size: 15px;
          &:hover {
            background-color: #409eff;
            color: #fff !important;
          }
        }
      }
      .addPageBtn {
        height: 50px;
        margin: 20px auto 0px;
        font-size: 16px;
        background-color: #409eff;
        color: #fff;
        width: 250px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .right {
      position: absolute;
      height: 100%;
      width: 262px;
      right: 0;
      top: 0;
    }

    .center {
      margin-left: 290px;
      margin-right: 290px;
      background: #f5f5f5;
      height: 100%;
      overflow: auto;
      // padding: 20px;

      .content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
      }
    }
  }

  .placeholder {
    text-align: center;
    color: #333;
  }
}
.pageItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
