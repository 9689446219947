<template>
  <el-dialog
    :title="activeEventType.text"
    :visible="isShowModal"
    width="70%"
    custom-class="dialogContent"
    @close="switchModalVisible(false)"
    :destroy-on-close="true"
  >
    <div class="allDirectives">
      <el-table
        :data="leftTableData"
        border
        style="width: 100%"
        @selection-change="
          (s) => {
            handleCheckedDChange(s, 1);
          }
        "
        >>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :prop="c.prop"
          :label="c.label"
          v-for="c in leftTableColumns"
          :key="c.prop"
        />
      </el-table>
    </div>
    <div class="switches">
      <div>
        <el-button
          type="primary"
          icon="el-icon-d-arrow-right"
          @click="addBindedData"
        />
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-d-arrow-left"
          @click="removeBindedData"
        />
      </div>
    </div>
    <div class="selectedDirectives">
      <el-alert
        class="statusTip"
        :closable="false"
        v-if="
          activeEventType.key == 'pressEvent' ||
          activeEventType.key == 'pressAgainEvent'
        "
        title="0为按钮初始状态，1为按钮按压状态"
        type="info"
      >
      </el-alert>
      <el-table
        :data="bindedData"
        border
        style="width: 100%"
        @selection-change="
          (s) => {
            handleCheckedDChange(s, 2);
          }
        "
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :prop="c.prop"
          :label="c.label"
          v-for="c in rightTableColumns"
          :key="c.prop"
        >
          <template slot-scope="scope">
            <el-select
              v-if="c.prop == 'status'"
              :value="scope.row.status"
              placeholder="请选择"
              @change="
                (v) => {
                  onStatusChange(v, scope.row);
                }
              "
            >
              <el-option
                v-for="item in statusesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span v-else>
              {{ scope.row[c.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isShowModal = false">取 消</el-button>
      <el-button type="primary" @click="bindButtonEvents">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@/components/Modal";
import { ECOLOGICAL_SIGN } from '@/constants/editor';
const commonColumn = [
  {
    prop: "buttonName",
    label: "按钮名称",
  },
  {
    prop: "alias",
    label: "按钮别名",
  },
];
export default {
  components: { Modal },
  props: {
    activeEventType: Object,
  },
  data() {
    return {
      isShowModal: false,
      //左侧表格的数据
      leftTableData: [],
      //右侧表格数据
      bindedData: [],
      //左侧选择的数据
      checkedLeft: [],
      //右侧选择的数据
      checkedRight: [],
      //左侧表格的列
      leftTableColumns: [],
      //右侧表格的列
      rightTableColumns: [],

      eventTypeColumnsConfig: {
        mutuallyEvent: {
          data: commonColumn,
        },
        jumpEvent: {
          data: [
            {
              prop: "pageName",
              label: "页面名称",
            },
          ],
        },
        pressEvent: {
          notSame: true,
          leftData: commonColumn,
          rightData: [
            ...commonColumn,
            {
              prop: "status",
              label: "状态",
            },
          ],
        },
        pressAgainEvent: {
          notSame: true,
          leftData: commonColumn,
          rightData: [
            ...commonColumn,
            {
              prop: "status",
              label: "状态",
            },
          ],
        },
      },
      statusesOptions: [
        {
          value: "0",
          label: "初始状态",
        },
        {
          value: "1",
          label: "按压状态",
        },
      ],
    };
  },
  mounted() {
    //初始化表格的列
    this.initTableColumns();
    //初始化左侧表格的数据
    this.initLeftTableData();
    //初始化右侧表格的数据
    this.initBindedData();
  },
  watch: {
    activeEventType(v) {
      this.initTableColumns(v);
      this.initLeftTableData(v);
      this.initBindedData(v);
    },
    curComponent() {
      this.initTableColumns();
      this.initLeftTableData();
      this.initBindedData();
    },
  },
  computed: mapState(["curComponent", "componentData", "selectedCurrentPage"]),
  methods: {
    switchModalVisible(bol) {
      this.isShowModal = bol;
      if (bol) {
        // 显示页面时候需要初始化
        this.initTableColumns();
        this.initLeftTableData();
        this.initBindedData();
      }
    },
    onStatusChange(v, row) {
      const i = this.bindedData.findIndex((i) => i.id == row.id);
      this.bindedData[i].status = v;
    },
    removeBindedData() {
      this.bindedData = _.differenceBy(
        this.bindedData,
        this.checkedRight,
        "id"
      );
    },
    addBindedData() {
      const { key } = this.activeEventType;
      const newA = _.cloneDeep(this.checkedLeft).reduce((re, item) => {
        re.push({
          id: item.id,
          buttonName: item.buttonName,
          alias: item.alias,
          status: "0",
        });
        return re;
      }, []);
      this.bindedData = _.uniqBy(
        [
          ...this.bindedData,
          ...(key == "pressEvent" || key == "pressAgainEvent"
            ? newA
            : this.checkedLeft),
        ],
        "id"
      );
    },
    initTableColumns(v) {
      const { key } = v || this.activeEventType;
      if (this.eventTypeColumnsConfig[key].notSame) {
        this.leftTableColumns = this.eventTypeColumnsConfig[key].leftData;
        this.rightTableColumns = this.eventTypeColumnsConfig[key].rightData;
      } else {
        const data = this.eventTypeColumnsConfig[key].data;
        this.leftTableColumns = data;
        this.rightTableColumns = data;
      }
    },
    initLeftTableData(v) {
      const { key } = v || this.activeEventType;
      if (key == "mutuallyEvent") {
        // const btns = this.componentData.filter(
        //   (c) => c.component == "v-button" && c.id != this.curComponent.id
        // );
        const btns = this.getOtherAllBtns(this.componentData, this.curComponent);
        this.leftTableData = btns.reduce((res, item) => {
          res.push({
            alias: item.style.alias,
            buttonName: item.propValue,
            id: item.id,
          });
          return res;
        }, []);
      } else if (key == "jumpEvent") {
        const pages = JSON.parse(sessionStorage.getItem("canvasData"));
        const pageData = [];
        for (const key in pages) {
          // 需要排除当前页面
          if (key === this.selectedCurrentPage) {
            continue;
          }
          pageData.push({
            id: key,
            pageName: pages[key][0]?.style?.pageName,
          });
        }
        this.leftTableData = pageData;
      } else if (key == "pressEvent") {
        const pages = JSON.parse(sessionStorage.getItem("canvasData"));

        pages[this.selectedCurrentPage] = _.cloneDeep(this.componentData);
        // const allBtns = _.flattenDeep(Object.values(pages)).filter(
        //   (c) => c.component == "v-button" && c.id != this.curComponent.id
        // );

        const allBtns = this.getOtherAllBtns(_.flattenDeep(Object.values(pages)), this.curComponent);
        this.leftTableData = allBtns.reduce((res, item) => {
          res.push({
            alias: item.style.alias,
            buttonName: item.propValue,
            id: item.id,
          });
          return res;
        }, []);
      }
    },
    getOtherAllBtns(all, curComp) {
      let finalArr = []
      for (let i = 0;i < all.length;i++) {
        if (all[i].component == "v-button" && all[i].id !== curComp.id) {
          finalArr.push(all[i]);
        } else if (all[i].compType === ECOLOGICAL_SIGN) {
          // 生态组件下的子组件也需要查找
          if (all[i].propValue && all[i].propValue.length !== 0) {
            for (let j = 0;j < all[i].propValue.length;j++) {
              if (all[i].propValue[j].component == "v-button" && all[i].propValue[j].id !== curComp.id) {
                finalArr.push(all[i].propValue[j]);
              }
            }
          }
        }
      }
      return finalArr;
    },
    initBindedData(v) {
      const { key } = v || this.activeEventType;
      if (key == "pressEvent" || key == "pressAgainEvent") {
        this.bindedData = this.curComponent.events[key] || [];
      } else {
        this.bindedData = this.leftTableData.filter((item) =>
          this.curComponent.events[key]?.includes(item.id)
        );
      }
    },
    handleCheckedDChange(s, type) {
      //type 1:左侧 2：右侧
      if (type == 1) {
        this.checkedLeft = s;
      } else {
        this.checkedRight = s;
      }
    },
    bindButtonEvents() {
      const { key } = this.activeEventType;
      if (key === 'jumpEvent' && this.bindedData.length > 1) {
        // 跳转事件最多可配置一个页面
        this.$message.error('跳转事件最多可配置一个页面');
        return;
      }
      this.$store.commit("bindEvents", {
        type: this.activeEventType.key,
        data:
          key == "pressEvent" || key == "pressAgainEvent"
            ? this.bindedData
            : this.bindedData.map((item) => item.id),
      });
      this.switchModalVisible(false);
    },
  },
};
</script>

<style lang="scss">
.dialogContent {
  .el-dialog__body {
    display: flex !important;
    height: 500px;
  }
  .allDirectives {
    flex: 1;
  }
  .selectedDirectives {
    flex: 1;
    .statusTip {
      margin-bottom: 12px;
    }
  }
  .switches {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    & div {
      margin-bottom: 12px;
    }
  }
}
</style>
