<template>
  <div
    id="editor"
    class="editor"
    :class="{ edit: isEdit }"
    :style="{
      width: changeStyleWithScale(projectStyleData.width) + 'px',
      height: changeStyleWithScale(projectStyleData.height) + 'px',
      transformOrigin: '0px 0px',
    }"
    @contextmenu="handleContextMenu"
    @mousedown="handleMouseDown"
  >
    <!-- 网格线 -->
    <Grid />

    <!--页面组件列表展示-->
    <Shape
      v-for="(item, index) in usableComponentsData"
      :key="item.id"
      :default-style="item.style"
      :style="getShapeStyle(item.style)"
      :active="item.id === (curComponent || {}).id"
      :element="item"
      :isEcological="item.compType === ECOLOGICAL_SIGN"
      :index="index"
      :class="{
        lock: item.isLock,
        commonZ: true,
        lowerZ: item.component == 'Picture',
      }"
    >
      <component
        :is="item.component"
        v-if="item.component != 'v-text'"
        :id="'component' + item.id"
        class="component"
        :style="getComponentStyle(item.style, item)"
        :prop-value="item.propValue"
        :styleDatas="item.style"
        :componentIndex="index"
        :element="item"
      />

      <component
        :is="item.component"
        v-else
        :id="'component' + item.id"
        class="component"
        :style="getComponentStyle(item.style)"
        :prop-value="item.propValue"
        :element="item"
        @input="handleInput"
      />
    </Shape>
    <!-- 右击菜单 -->
    <!-- <ContextMenu /> -->
    <!-- 标线 -->
    <MarkLine />
    <!-- 选中区域 -->
    <Area
      v-show="isShowArea"
      :start="start"
      :width="width"
      :height="height"
      @areaMove="handleAreaMove"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Shape from './Shape'
import { getStyle, getComponentRotatedStyle } from '@/utils/style'
import { $ } from '@/utils/utils'
import ContextMenu from './ContextMenu'
import MarkLine from './MarkLine'
import Area from './Area'
import eventBus from '@/utils/eventBus'
import Grid from './Grid'
import { changeStyleWithScale } from '@/utils/translate'
import { ECOLOGICAL_SIGN } from '@/constants/editor'

export default {
  components: { Shape, ContextMenu, MarkLine, Area, Grid },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editorX: 0,
      editorY: 0,
      start: {
        // 选中区域的起点
        x: 0,
        y: 0,
      },
      width: 0,
      height: 0,
      isShowArea: false,
      ECOLOGICAL_SIGN,
    }
  },
  computed: {
    ...mapState([
      'componentData',
      'curComponent',
      'canvasStyleData',
      'projectStyleData',
      'editor',
      'areaData',
    ]),
    usableComponentsData() {
      const ret = this.componentData.filter((c) => c.id != 'Back')
      // console.log(JSON.stringify(ret));
      return ret
    },
  },
  watch: {
    projectStyleData: {
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          this.setEditorReSize()
        })
      },
      deep: true,
    },
  },
  created() {
    const _this = this
    document.onkeydown = function (e) {
      _this.keydownHandle(e)
    }
  },
  mounted() {
    // 获取编辑器元素
    this.setEditorReSize()
    this.$store.commit('getEditor')

    eventBus.$on('hideArea', () => {
      this.hideArea()
    })
  },
  methods: {
    changeStyleWithScale,
    keydownHandle(e) {
      if (
        !this.isShowArea &&
        (!this.curComponent || this.curComponent.id === 'Back')
      ) {
        return
      }
      e = window.event || e
      switch (e.keyCode) {
        case 37: // 左
          if (this.isShowArea) {
            this.handleAreaMove({ missX: -1 })
            this.$store.commit('recordSnapshot')
            break
          }
          let finalLeft = this.curComponent.style.left - 1
          if (finalLeft < 0) {
            finalLeft = 0
          }
          if (finalLeft !== this.curComponent.style.left) {
            this.$store.commit('setShapeStyle', {
              left: finalLeft,
            })
          }
          break
        case 38: // 上
          if (this.isShowArea) {
            this.handleAreaMove({ missY: -1 })
            this.$store.commit('recordSnapshot')
            break
          }
          let finalTop = this.curComponent.style.top - 1
          if (finalTop < 0) {
            finalTop = 0
          }
          if (finalTop !== this.curComponent.style.top) {
            this.$store.commit('setShapeStyle', {
              top: finalTop,
            })
          }
          break
        case 39: // 右
          if (this.isShowArea) {
            this.handleAreaMove({ missX: 1 })
            this.$store.commit('recordSnapshot')
            break
          }
          let left = this.curComponent.style.left + 1
          if (
            left + this.curComponent.style.width >
            this.projectStyleData.width
          ) {
            left = this.projectStyleData.width - this.curComponent.style.width
          }
          if (left !== this.curComponent.style.left) {
            this.$store.commit('setShapeStyle', {
              left: left,
            })
          }
          break
        case 40: // 下
          if (this.isShowArea) {
            this.handleAreaMove({ missY: 1 })
            this.$store.commit('recordSnapshot')
            break
          }
          let top = this.curComponent.style.top + 1
          if (
            top + this.curComponent.style.height >
            this.projectStyleData.height
          ) {
            top = this.projectStyleData.height - this.curComponent.style.height
          }
          if (top !== this.curComponent.style.top) {
            this.$store.commit('setShapeStyle', {
              top: top,
            })
          }
          break
      }
    },
    setEditorReSize() {
      const parentElem = this.$el.parentElement
      const parentW = parentElem.clientWidth
      const parentH = parentElem.clientHeight
      const currentActualW = this.$el.clientWidth
      const currentActualH = this.$el.clientHeight
      const wPercent = parseFloat((parentW / currentActualW).toFixed(2))
      const hPercent = parseFloat((parentH / currentActualH).toFixed(2))
      let finalPer
      // 取小一点的比例，来保证完全展示在页面中
      if (wPercent < hPercent) {
        finalPer = wPercent
      } else {
        finalPer = hPercent
      }
      this.$el.setAttribute('data-scale', finalPer)
      this.$el.style.transform = `scale(${finalPer}, ${finalPer}) translate(-50%, -50%)`
    },
    getPosition(e) {
      // 拿到画布的位置信息
      const rectInfo = this.editor.getBoundingClientRect()
      this.editorX = rectInfo.x
      this.editorY = rectInfo.y
      // 缩放比例
      let currentScale = this.$el.getAttribute('data-scale')
      currentScale = +currentScale || 1
      const startX = e.clientX
      const startY = e.clientY
      return {
        x: (startX - this.editorX) / currentScale,
        y: (startY - this.editorY) / currentScale,
      }
    },
    handleMouseDown(e) {
      // 右键点击不可拖拽选择区域
      if (e.button === 2) {
        // 如果是右键点击，且在多选区域里 不做处理
        let isInArea = false
        const { x, y } = this.getPosition(e)
        if (this.areaData) {
          const { style, components } = this.areaData
          if (components.length) {
            const { left, top, width, height } = style
            if (
              x >= left &&
              x <= left + width &&
              y >= top &&
              y <= top + height
            ) {
              isInArea = true
            }
          }
        }
        if (!isInArea) {
          this.hideArea()
        }
        return
      }
      // 如果没有选中组件 在画布上点击时需要调用 e.preventDefault() 防止触发 drop 事件
      if (
        !this.curComponent ||
        (this.curComponent.component != 'v-text' &&
          this.curComponent.component != 'rect-shape')
      ) {
        e.preventDefault()
      }

      this.hideArea()

      // 获取编辑器的位移信息，每次点击时都需要获取一次。主要是为了方便开发时调试用。
      const rectInfo = this.editor.getBoundingClientRect()
      this.editorX = rectInfo.x
      this.editorY = rectInfo.y

      let currentScale = this.$el.getAttribute('data-scale')
      currentScale = +currentScale || 1
      const startX = e.clientX
      const startY = e.clientY
      this.start.x = (startX - this.editorX) / currentScale
      this.start.y = (startY - this.editorY) / currentScale
      // 展示选中区域
      this.isShowArea = true

      const move = (moveEvent) => {
        this.width = Math.abs(moveEvent.clientX - startX) / currentScale
        this.height = Math.abs(moveEvent.clientY - startY) / currentScale
        if (moveEvent.clientX < startX) {
          this.start.x = (moveEvent.clientX - this.editorX) / currentScale
        }

        if (moveEvent.clientY < startY) {
          this.start.y = (moveEvent.clientY - this.editorY) / currentScale
        }
      }

      const up = (e) => {
        document.removeEventListener('mousemove', move)
        document.removeEventListener('mouseup', up)

        if (e.clientX == startX && e.clientY == startY) {
          this.hideArea()
          return
        }

        this.createGroup()
      }

      document.addEventListener('mousemove', move)
      document.addEventListener('mouseup', up)
    },

    hideArea() {
      this.isShowArea = 0
      this.width = 0
      this.height = 0

      this.$store.commit('setAreaData', {
        style: {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        },
        components: [],
      })
    },

    createGroup() {
      // 获取选中区域的组件数据
      const areaData = this.getSelectArea()
      if (areaData.length <= 1) {
        this.hideArea()
        return
      }
      // 根据选中区域和区域中每个组件的位移信息来创建 Group 组件
      // 要遍历选择区域的每个组件，获取它们的 left top right bottom 信息来进行比较
      let top = Infinity,
        left = Infinity
      let right = -Infinity,
        bottom = -Infinity
      areaData.forEach((component, index) => {
        let style = {}
        if (component.component == 'Group') {
          // 组合组件拖拽或调整大小后不更新内部组件的style
          // 但是组合组件的style是准确的 所以可以直接使用这个方法去计算范围
          style = getComponentRotatedStyle(component.style)
        } else {
          style = getComponentRotatedStyle(component.style)
        }
        if (style.left < left) left = style.left
        if (style.top < top) top = style.top
        if (style.right > right) right = style.right
        if (style.bottom > bottom) bottom = style.bottom
      })

      this.start.x = left
      this.start.y = top
      this.width = right - left
      this.height = bottom - top
      // 清除单个组件的选中
      this.$store.commit('setCurComponent', {
        component: null,
        index: null,
      })
      // 设置选中区域位移大小信息和区域内的组件数据
      this.$store.commit('setAreaData', {
        style: {
          left,
          top,
          width: this.width,
          height: this.height,
        },
        components: areaData,
      })
    },

    getSelectArea() {
      const result = []
      // 区域起点坐标
      const { x, y } = this.start
      // 计算所有的组件数据，判断是否在选中区域内
      this.usableComponentsData.forEach((component) => {
        if (component.isLock) return

        const { left, top, width, height } = getComponentRotatedStyle(
          component.style
        )
        if (
          x <= left &&
          y <= top &&
          left + width <= x + this.width &&
          top + height <= y + this.height
        ) {
          result.push(component)
        }
      })

      // 返回在选中区域内的所有组件
      return result
    },

    handleContextMenu(e) {
      e.stopPropagation()
      e.preventDefault()

      // 计算菜单相对于编辑器的位移
      let target = e.target
      while (target instanceof SVGElement) {
        target = target.parentNode
      }

      while (
        target.className &&
        !target.className.includes('shape') &&
        !target.className.includes('backWrap') &&
        !target.className.includes('area')
      ) {
        target = target.parentNode
      }

      let top = target.offsetTop
      let left = target.offsetLeft

      let currentScale = this.$el.getAttribute('data-scale')
      currentScale = +currentScale || 1
      let editorTop =
        (this.$el.parentElement.clientHeight -
          this.$el.clientHeight * currentScale) /
        2
      top = top * currentScale + editorTop + e.offsetY * currentScale
      left = left * currentScale + e.offsetX * currentScale
      // while (!target.className.includes("editor")) {
      //   left += target.offsetLeft;
      //   top += target.offsetTop;
      //   target = target.parentNode;
      // }
      this.$store.commit('showContextMenu', { top, left })
    },

    getShapeStyle(style) {
      const result = {}
      ;['width', 'height', 'top', 'left', 'rotate'].forEach((attr) => {
        if (attr != 'rotate') {
          result[attr] = style[attr] + 'px'
        } else {
          result.transform = 'rotate(' + style[attr] + 'deg)'
        }
      })

      return result
    },

    getComponentStyle(style, item) {
      let styleObj = getStyle(style, [
        'top',
        'left',
        'width',
        'height',
        'rotate',
      ])
      if (item && item.component === 'StatusLight') {
        // 状态灯
        if (style.background === 'icon' && style.backgroundImage) {
          delete styleObj.backgroundColor
        } else {
          delete styleObj.backgroundImage
        }
      }
      return styleObj
    },

    handleInput(element, value) {
      // 根据文本组件高度调整 shape 高度
      this.$store.commit('setShapeStyle', {
        height: this.getTextareaHeight(element, value),
      })
    },

    getTextareaHeight(element, text) {
      let { lineHeight, fontSize, height } = element.style
      if (lineHeight === '') {
        lineHeight = 1.5
      }

      const newHeight = (text.split('<br>').length - 1) * lineHeight * fontSize
      return height > newHeight ? height : newHeight
    },
    handleAreaMove(moveInfo) {
      if ('missX' in moveInfo) {
        const afterX = this.start.x + moveInfo.missX
        if (afterX < 0) {
          // 如果向左导致x小于0， 不能再往左拖动
          moveInfo.missX = -this.start.x
        } else if (afterX + this.width > this.projectStyleData.width) {
          // 如果拖动后右侧超出右边框
          moveInfo.missX =
            this.projectStyleData.width - this.width - this.start.x
        }
        this.start.x += moveInfo.missX
      }
      if ('missY' in moveInfo) {
        const afterY = this.start.y + moveInfo.missY
        if (afterY < 0) {
          // 如果向上导致y小于0， 不能再往上拖动
          moveInfo.missY = -this.start.y
        } else if (afterY + this.height > this.projectStyleData.height) {
          // 如果拖动后右侧超出右边框
          moveInfo.missY =
            this.projectStyleData.height - this.height - this.start.y
        }
        this.start.y += moveInfo.missY
      }
      // 修改当前组件样式
      this.$store.commit('setAreaStyle', moveInfo)
    },
  },
}
</script>

<style lang="scss" scoped>
.editor {
  position: absolute;
  background: #fff;
  margin: auto;
  left: 50%;
  top: 50%;
  .lock {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}
.lowerZ {
  z-index: 1 !important;
}
.commonZ {
  z-index: 2;
}
.edit {
  .component {
    outline: none;
    width: 100%;
    height: 100%;
  }
}
</style>
