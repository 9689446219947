<template>
    <el-dialog
        title="批量设置"
        :visible="isShowModal"
        width="500px"
        :append-to-body="true"
        :destroy-on-close="true"
        @close="dialogClose"
    >
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="IP地址">
                <el-input v-model="form.ip" placeholder="请输入IP"></el-input>
            </el-form-item>
            <el-form-item label="端口">
                <el-input v-model="form.port" placeholder="请输入端口号"></el-input>
            </el-form-item>
            <el-form-item label="延时（毫秒）">
                <el-input v-model="form.delay" placeholder="请输入延时时间"></el-input>
            </el-form-item>
            <el-form-item label="次数">
                <el-input v-model="form.counts" placeholder="请输入次数"></el-input>
            </el-form-item>
            <el-form-item v-if="isMustValid" label="返回值校验">
                <el-input v-model="form.mustValid" placeholder="请输入返回值校验"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="isShowModal = false">取 消</el-button>
            <el-button type="primary" @click="confirmHandle">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        isMustValid: {
            type: Boolean
        }
    },
    data() {
        return {
            isShowModal: false,
            form: {
                ip: '',
                port: '',
                delay: '',
                counts: ''
            }
        };
    },
    methods: {
        initForm() {
            this.form = {
                ip: '',
                port: '',
                delay: '',
                counts: ''
            };
        },
        show() {
            this.initForm();
            this.isShowModal = true;
        },
        dialogClose() {
            this.isShowModal = false;
        },
        confirmHandle() {
            this.$emit('confirm', this.form);
            this.isShowModal = false;
        }
    }
}
</script>