<template>
  <div>
    <template v-for="n in ACTIVE_DETECTION_CONFIG">
      <div
        class="item"
        v-if="
          n.parentKey
            ? curComponent.activeDetectionConfig[n.parentKey] === '1'
            : true
        "
        :key="n.key"
      >
        <div class="label">{{ n.label }}</div>
        <div class="comp">
          <span v-if="!!n.prefix" class="prefixLabel">{{ n.prefix }}</span>
          <template v-if="n.type === 'input'">
            <el-input-number
              v-if="n.inputType === 'number'"
              v-model="curComponent.activeDetectionConfig[n.key]"
              :placeholder="`请输入${n.label}`"
              @keydown.native.stop
              :controls="false"
              :min="limitVal(n.key, 'min')"
              :precision="limitVal(n.key, 'precision')"
              @change="inputValChange(n.key)"
            />
            <el-input
              v-else
              v-model="curComponent.activeDetectionConfig[n.key]"
              :placeholder="`请输入${n.label}`"
              @keydown.native.stop
              @change="inputValChange(n.key)"
            />
          </template>
          <el-radio-group
            v-else-if="n.type === 'radio'"
            v-model="curComponent.activeDetectionConfig[n.key]"
          >
            <el-radio
              v-for="item in n.options"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
          <el-select
            v-else-if="n.type === 'select'"
            v-model="curComponent.activeDetectionConfig[n.key]"
            placeholder="请选择"
            :style="n.width ? { width: n.width } : {}"
          >
            <el-option
              v-for="o in n.options"
              :key="o.value"
              :label="o.label"
              :value="o.value"
            >
            </el-option>
          </el-select>
          <template v-else-if="n.key === 'bindDirectives'">
            <el-button type="primary" size="small" @click="addDirectionClick"
              >添加指令</el-button
            >
            <div
              class="directives"
              v-if="
                curComponent.activeDetectionConfig[n.key] &&
                curComponent.activeDetectionConfig[n.key].length !== 0
              "
            >
              <el-tag
                type="info"
                v-for="d in curComponent.activeDetectionConfig[n.key]"
                :key="d.id"
                closable
                @close="deleteDirective(d, n.key)"
                >{{ d.instruction_name }}</el-tag
              >
            </div>
          </template>
          <template v-else-if="n.type === 'resultRadio'">
            <el-radio-group v-model="curComponent.activeDetectionConfig[n.key]">
              <el-radio label="color">颜色</el-radio>
              <el-radio label="icon">图标</el-radio>
            </el-radio-group>
            <div>
              <el-color-picker
                v-if="curComponent.activeDetectionConfig[n.key] === 'color'"
                v-model="curComponent.activeDetectionConfig[n.colorKey]"
              />
              <UploadArea
                v-if="curComponent.activeDetectionConfig[n.key] === 'icon'"
                :handler="
                  () => {
                    showImagesResourceModal(n.iconKey)
                  }
                "
                :deleteHandler="
                  () => {
                    resetImageDefaultSrc(n.iconKey)
                  }
                "
                :src="`url(${curComponent.activeDetectionConfig[n.iconKey]})`"
              />
            </div>
          </template>
          <span v-if="!!n.suffix" class="suffixLabel">{{ n.suffix }}</span>
          <div v-if="!!errorMsg(n.key)" class="attr-error-tip">
            {{ errorMsg(n.key) || '' }}
          </div>
        </div>
      </div>
    </template>
    <!-- 图片资源的modal -->
    <ImagesResourceModal
      ref="imagesResourceModal"
      :modalOperationKey="modalOperationKey"
      @confirm="imageModalConfirm"
    />
    <!-- 选择指令 -->
    <DirectivesModalForLight
      ref="directivesModal"
      @confirm="directivesModalConfirm"
    />
  </div>
</template>
<script>
import {
  ACTIVE_DETECTION_CONFIG,
  ATTRS_VALIDATE_MAP,
} from '../utils/attrNameData'
import { mapState } from 'vuex'
import eventBus from '@/utils/eventBus'
import UploadArea from '@/components/UploadArea'
import ImagesResourceModal from '@/components/ImagesResourceModal'
import DirectivesModalForLight from './DirectivesModalForLight'

export default {
  data() {
    return {
      ACTIVE_DETECTION_CONFIG,
      modalOperationKey: '',
    }
  },
  components: {
    UploadArea,
    ImagesResourceModal,
    DirectivesModalForLight,
  },
  computed: {
    ...mapState(['curComponent', 'curErrorMap', 'selectedCurrentPage']),
    limitVal() {
      return (key, propKey) => {
        const val = ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key][propKey]
        if (val !== null && val !== undefined && val !== '') {
          return val
        }
        return undefined
      }
    },
    errorMsg() {
      return (key) => {
        const id = this.curComponent.id
        return this.curErrorMap[id] && this.curErrorMap[id][key]
      }
    },
  },
  methods: {
    imageModalConfirm(info) {
      this.curComponent.activeDetectionConfig[this.modalOperationKey] =
        info.file_down_path
      this.curComponent.activeDetectionConfig[this.modalOperationKey + 'Info'] =
        info
    },
    addDirectionClick() {
      this.$refs.directivesModal.switchModalVisible(true)
    },
    directivesModalConfirm(ret) {
      this.curComponent.activeDetectionConfig.bindDirectives = [ret]
    },
    deleteDirective(d, key) {
      const tags = this.curComponent.activeDetectionConfig[key]
      const ret = tags.filter((item) => item.temId != d.temId)
      this.curComponent.activeDetectionConfig[key] = ret
    },
    inputValChange(key) {
      const validateFunc =
        ATTRS_VALIDATE_MAP[key] && ATTRS_VALIDATE_MAP[key].validate
      if (validateFunc && typeof validateFunc === 'function') {
        eventBus.$emit('inputValidate', {
          key,
          val: this.curComponent.activeDetectionConfig[key],
          id: this.curComponent.id,
          otherVal: this.curComponent.activeDetectionConfig,
        })
      }
    },
    showImagesResourceModal(key) {
      this.modalOperationKey = key
      this.$refs.imagesResourceModal.switchDialogVisible(true)
    },
    resetImageDefaultSrc(key) {
      this.curComponent.activeDetectionConfig[key] = ''
      this.curComponent.activeDetectionConfig[key + 'Info'] = {}
    },
  },
}
</script>
<style lang="scss">
.item {
  .el-input-number .el-input__inner {
    text-align: left;
  }
  .comp {
    .attr-error-tip {
      left: 0px;
      top: 31px;
      line-height: normal;
    }
  }
}
</style>
<style lang="scss" scoped>
.item {
  display: flex;
  flex-direction: row;
  line-height: 32px;
  margin-bottom: 15px;
  .label {
    font-size: 12px;
    color: #606266;
    flex-wrap: nowrap;
    min-width: 56px;
    padding-right: 4px;
    flex-shrink: 0;
  }
  .comp {
    position: relative;
    .prefixLabel {
      margin-right: 3px;
    }
    .suffixLabel {
      margin-left: 3px;
    }
  }
}
</style>
